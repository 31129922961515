import React from "react";

export default function Header() {
  return (
    <div id="navbar-wrapper">
      <nav className="navbar navbar-inverse">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              {/* <div className="navbar-header">
                        <a style={{ color: "#1b1818" }} onClick={toggleBar} className="navbar-brand cursor-pointer" id="sidebar-toggle"><i className="fa fa-bars"></i></a>
                    </div> */}
              <h4
                style={{
                  fontFamily: " system-ui",
                  fontSize: "17px",
                }}
                className="content-title heading-3 mb-0 text-white"
              >
                {" "}
                Open Tariff Digitization
              </h4>
            </div>

            <div className="custom-dropdown">
              <div className="dropdown">
                <a
                  className="dropdown-toggle no-arrow d-flex align-items-center justify-content-center"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-bs-offset="0,10"
                >
                  <div className=" d-flex align-items-center justify-content-center me-2 drop-img">
                    <img src="../../assets/img/AvatarAbcl.png" />
                  </div>
                </a>
                {/* <div className="dropdown-menu mw-115 show" >
                            <a className="dropdown-item cursor-pointer"><span className="me-2 img-icon"><img
                            src="../../assets/img/manage-account.svg" /></span>Profile</a>
                            <a 
                             className="dropdown-item cursor-pointer" ><span className="me-2 img-icon" ><img
                                src="../../assets/img/logout.svg" /></span>Logout</a>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
