import React, { useEffect, useState } from "react";
import "./styles/preview.css";
import Pagination from "@mui/material/Pagination";
import * as XLSX from "xlsx";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import { showToaster } from "../services/helper";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ExportOsha300ACSV, fetchExtractedCsv } from "../services/api-servics";
const apiUrl = process.env.REACT_APP_API_URL;
// import { saveAs } from 'file-saver';
const steps = ["Data Extraction", "Verification", "Mapping"];
export default function Preview(props) {
  console.log(props, "propsprops");
  const [inputDataImgs, setInputDataImgs] = useState([]);
  const [response, setResponse] = useState();
  const [uploadProgress, setUploadProgress] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [colHeaders, setColHeaders] = useState([]);
  const [columns, setColumns] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [extractedData, setExtractedData] = useState();
  // const colHeaders = ['Name', 'Age', 'City'];
  // const columns = [
  //   { data: 'name', type: 'text' },
  //   { data: 'age', type: 'text' },
  //   { data: 'city', type: 'text' },
  // ];

  const blobToArrayBuffer = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };
  const getExtractedData = async (value, object_id) => {
    setIsLoading(true);
    try {
      let data = {
        id: object_id,
        page_no: value - 1,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      await fetch(`${apiUrl}/v1/base/return_csv_result/`, requestOptions)
        .then((result) => result.blob())
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          const headers = jsonData[0];
          const rows = jsonData.slice(1);
          setColHeaders(headers);
          let array = [];
          headers.map((data) => {
            let details = {
              data: data.toLowerCase(),
              type: "text",
            };
            array.push(details);
          });
          setIsLoading(false);
          setColumns(array);
          setData(rows);
        })

        .catch((error) => {
          setIsLoading(true);
          console.error("Error fetching or downloading the file:", error);
        });
    } catch (e) {
      setIsLoading(true);
      console.error("Error fetching extracted data:", e);
    }
  };

  const nextImage = (value) => {
    console.log("value", value);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % inputDataImgs.length);
    getExtractedData(value, response?.objectId);
  };
  // const prevImage = () => {
  //   setCurrentIndex(
  //     (prevIndex) =>
  //       (prevIndex - 1 + inputDataImgs.length) % inputDataImgs.length
  //   );
  // };

  useEffect(() => {
    const { location } = props;
    const state = location?.state;
    if (state) {
      setInputDataImgs(state.details.jpgs);
      setResponse(state);
      getExtractedData(1, state.objectId);
    }
  }, [props]);

  const CustomStepper = styled(Stepper)(({ theme }) => ({
    backgroundColor: "transparent",
    "& .MuiStepLabel-root": {
      color: theme.palette.primary.main,
      "&.Mui-active": {
        color: theme.palette.secondary.main,
      },
      "&.Mui-completed": {
        color: theme.palette.success.main,
      },
    },
    "& .MuiStepIcon-root": {
      "&.Mui-active": {
        color: theme.palette.secondary.main,
      },
      "&.Mui-completed": {
        color: theme.palette.success.main,
      },
    },
  }));
  const handleExport = async () => {
    // Generate a binary string
    console.log("adaasdaaasdasd", data);
    // Convert data to a worksheet
    // data.unshift(columns)
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert binary string to Blob
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    const fileName = "Names2test.xlsx";

    // Prepare FormData
    const formData = new FormData();
    formData.append("file", file, fileName);
       history.push({
        pathname: "/mapping",
        state: {
          objectId: response?.objectId,
          column:columns
        },
      });

    // Send API request
    // try {
    //   const result = await fetch(
    //     `${apiUrl}/v1/base/update_csv_file/?page_no=${currentIndex}&id=${response?.objectId}&filepath=Names2test.xlsx`,
    //     {
    //       method: "POST",
    //       body: formData,
    //     }
    //   );
    //   console.log(result, "filefilefilefilefilefile");
    //   history.push({
    //     pathname: "/mapping",
    //     state: {
    //       objectId: response?.objectId,
    //       column:columns
    //     },
    //   });
    //   if (result.ok) {
    //     console.log("File sent successfully!");
    //   } else {
    //     console.error("Error sending file:", result.statusText);
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const handleAfterChange = (changes) => {
    if (changes) {
      changes.forEach(([row, prop, oldValue, newValue]) => {
        console.log(
          `Changed row ${row}, column ${prop} from ${oldValue} to ${newValue}`
        );
        // Update the data state if necessary
        const updatedData = [...data];
        updatedData[row][prop] = newValue;
        console.log(updatedData, "updatedData");
        setData(updatedData);
      });
    }
  };
  return (
    <>
      <div className="container_preview">
        <div className="header_container">
          <div className="header_Left_container">Open Tariff Digitization</div>
          <div className="header_right_container">
            <div>
              <img src="../../assets/img/AvatarAbcl.png" />
            </div>
            <div>
              <img src="../../assets/img/chevron-down.png" />
            </div>
          </div>
        </div>
        <div className="container_inner">
          <div className="stepper">Data Extraction</div>
          {/* <Stepper
            steps={[
              { label: "Step 1" },
              { label: "Step 2" },
              { label: "Step 3" },
            ]}
            activeStep={2}
          /> */}
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={1} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        color: "primary.main",
                        "&.Mui-active": {
                          color: "secondary.main",
                        },
                        "&.Mui-completed": {
                          color: "#0162DD",
                          fontWeight: "700",
                        },
                      },
                      "& .MuiStepIcon-root": {
                        "&.Mui-active": {
                          color: "secondary.main",
                        },
                        "&.Mui-completed": {
                          color: "success.main",
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div className="i_container">
            <div className="data_container">
              <div className="data_container_head">Input Data</div>
              <div className="data_img">
                {inputDataImgs && (
                  <img
                    // width="=50%"
                    // height="610px"
                    src={inputDataImgs[currentIndex]?.relative_path}
                    alt={`Slide ${currentIndex}`}
                    // style={{ maxWidth: "100%" }}
                  />
                )}
              </div>
            </div>
            <div className="data_container">
              <div className="data_container_head">Extracted Data</div>
              <div className="data_img">
                {data.length > 0 ? (
                  <HotTable
                    data={data}
                    colHeaders={colHeaders}
                    width="100%"
                    height="auto"
                    stretchH="all"
                    licenseKey="non-commercial-and-evaluation"
                    afterChange={handleAfterChange}
                    filters={true}
                    dropdownMenu={true}
                    columnSorting={true}
                    manualColumnResize={true}
                    contextMenu={true}
                    // Excel-like styling options
                    cell={(row, col) => {
                      return {
                        renderer: (
                          instance,
                          td,
                          row,
                          col,
                          prop,
                          value,
                          cellProperties
                        ) => {
                          // Change cell color based on conditions
                          if (value < 50) {
                            td.style.backgroundColor = "#ffcccb"; // Light red for values < 50
                          } else if (value >= 50 && value < 100) {
                            td.style.backgroundColor = "#ffffcc"; // Light yellow for values between 50 and 100
                          } else {
                            td.style.backgroundColor = "#ccffcc"; // Light green for values >= 100
                          }
                          // Call default text renderer
                          Handsontable.renderers.TextCell.renderer.apply(
                            this,
                            arguments
                          );
                        },
                      };
                    }}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
              {isLoading && (
                <>
                  <div style={{ marginTop: "161px" }}>
                    <CircularProgress />
                  </div>
                  <span style={{ marginTop: "121px" }}>
                    Please wait while we extract your data
                  </span>
                </>
              )}

              {/* <button onClick={handleExport}>Export Excel</button> */}
            </div>
          </div>
        </div>
        <button
          // disabled={true}
          type="submit"
          style={{
            backgroundColor: "#3f9a28",
            marginTop: "-20px",
            height: "10px",
            float: "right",
          }}
          onClick={handleExport}
          className="btn btn-success  btn-sm"
        >
          <img
            src="../../assets/img/check.png"
            style={{ width: "20px" }}
            className="me-1"
          />{" "}
          Update
        </button>
        <div className="pagination-navsdaSS">
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  color: "#0162DD",
                  fontWeight: "700",
                  backgroundColor: "#D4F5FF",
                },
              },
              "& .MuiPagination-ul": {
                display: "flex",
                justifyContent: "center",
                // backgroundColor: "#D4F5FF",

                // width: "fit-content",
                // backgroundColor:" #8ca0ff",
                padding: "5px",
              },
            }}
            count={inputDataImgs.length}
            onChange={(event, page) => nextImage(page)}
          />
        </div>
      </div>
      {/* <div class="footer">
        <img src="../../assets/img/techsvg.png" />
      </div> */}
    </>
  );
}
