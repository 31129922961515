import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Box from "@mui/material/Box";
export default function FinalOutput(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [progress, setProgress] = useState([]);
  const [colHeaders, setColHeaders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    try {
      let data = {
        id: props?.location?.state?.objectId,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      fetch(`${apiUrl}/v1/base/viewFullAsExcelData/`, requestOptions)
        .then((result) => result.blob())
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          console.log("arrayBuffer",arrayBuffer);
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          const headers = jsonData[0];
          const rows = jsonData.slice(1);
          setColHeaders(headers);
          let array = [];
          headers.map((data) => {
            let details = {
              data: data.toLowerCase(),
              type: "text",
            };
            array.push(details);
          });
          setColumns(array);
          setData(rows);
        })

        .catch((error) => {
          console.error("Error fetching or downloading the file:", error);
        });
    } catch (e) {
      console.error("Error fetching extracted data:", e);
    }
  }, []);
    const DownloadData = async() => {
       
        let data = {
            id: props?.location?.state?.objectId,
          };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          };
    
          fetch(`${apiUrl}/v1/base/viewFullAsExcelData/`, requestOptions)
            .then((result) => result.blob())
            .then((blob) => {
              var downloadLink = document.createElement("a");
         
              downloadLink.href = window.URL.createObjectURL(blob);
         
              // Set the download attribute
              downloadLink.download = `openTariff.xlsx`;
         
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            })
         
            .catch((error) => console.log("error", error));
}
  return (
    <div className="container_preview">
      <div className="header_container">
        <div className="header_Left_container">Open Tariff Digitization</div>
        <div className="header_right_container">
          <div>
            <img src="../../assets/img/AvatarAbcl.png" />
          </div>
          <div>
            <img src="../../assets/img/chevron-down.png" />
          </div>
        </div>
      </div>
      <button
        type="submit"
        style={{
          backgroundColor: "#18acd5",
          marginTop: "10px",
          height: "10px",
            float: "right",
          marginRight: "25px"
        }}
          onClick={()=>DownloadData()}
        className="btn btn-primary  btn-sm"
      >
        <img
          src="../../assets/img/check.png"
          style={{ width: "20px" }}
          className="me-1"
        />{" "}
        Download
      </button>
          <div className="i_container_final">
        
        <div className="data_container_final">
          <div className="data_container_head">Final Data</div>
          <div>
            {data.length > 0 ? (
              <HotTable
                data={data}
                colHeaders={colHeaders}
                width="100%"
                height="auto"
                stretchH="all"
                licenseKey="non-commercial-and-evaluation"
                // afterChange={handleAfterChange}
                filters={true}
                dropdownMenu={true}
                columnSorting={true}
                manualColumnResize={true}
                contextMenu={true}
                // Excel-like styling options
                cell={(row, col) => {
                  return {
                    renderer: (
                      instance,
                      td,
                      row,
                      col,
                      prop,
                      value,
                      cellProperties
                    ) => {
                      // Change cell color based on conditions
                      if (value < 50) {
                        td.style.backgroundColor = "#ffcccb"; // Light red for values < 50
                      } else if (value >= 50 && value < 100) {
                        td.style.backgroundColor = "#ffffcc"; // Light yellow for values between 50 and 100
                      } else {
                        td.style.backgroundColor = "#ccffcc"; // Light green for values >= 100
                      }
                      // Call default text renderer
                      Handsontable.renderers.TextCell.renderer.apply(
                        this,
                        arguments
                      );
                    },
                  };
                }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
          {/* {isLoading && (
        <>
          <div style={{ marginTop: "161px" }}>
            <CircularProgress />
          </div>
          <span style={{ marginTop: "121px" }}>
            Please wait while we extract your data
          </span>
        </>
      )} */}

          {/* <button onClick={handleExport}>Export Excel</button> */}
        </div>
      </div>
    </div>
  );
}
