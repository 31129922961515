
import Dashboard from './components/dashboard';
import DetailsPage from './components/detailPage';
import Preview from './components/preview';
import MappingDetails from './components/mappingData';
import FinalOutPut from './components/finalOutput';
import './App.css'
import { Route, Switch, BrowserRouter } from "react-router-dom";
function App() {

  return (
    <BrowserRouter >
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/preview" exact component={Preview} />
        <Route path="/details" exact component={DetailsPage} />
        <Route path="/mapping" exact component={MappingDetails} />
        <Route path="/finaldata" exact component={FinalOutPut} />
        
      </Switch>
    </BrowserRouter >
  );
}

export default (App);