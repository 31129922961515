import React, { useState, useEffect, useRef } from "react";
import Header from "./header";
import { showToaster } from "../services/helper";
import { uploadPDF, getHospitalName } from "../services/api-servics";
import { useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Select from "react-select";
export default function Dashboard() {
  const [uploadProgress, setUploadProgress] = useState(false);
  const [confirmmsg, setconfirmmsg] = useState({});
  const [questionDetails, setQuestionDetails] = useState();
  const history = useHistory();
  const [listUserCategory, setListUserCategory] = useState();
  const [userTypeCategory, setUserTypeCategory] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const hiddenFileInput = useRef(null);
  const handleClick1 = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  // File Selection
  const handleFileSelection = (file) => {
    const fileUploaded = file.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.type !== "application/pdf") {
        return showToaster("Please upload a PDF file to continue.", "warning");
      }
      setSelectedFile(fileUploaded);
    }
  };
  // File Upload
  const handleFileUpload = async () => {
    if (!selectedFile) {
      return showToaster("Please select a file to continue.", "warning");
    }

    setUploadProgress(true);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("hospital_name", listUserCategory.label);

    try {
      const response = await uploadPDF(formData);

      if (response && response.data) {
        showToaster("Documents Uploaded successfully", "success");
        setUploadProgress(false);
        setQuestionDetails(response.data);
        history.push({
          pathname: "/preview",
          state: {
            details: response.data.data,
            objectId: response.data.object_id,
          },
        });
      } else {
        setUploadProgress(false);
        showToaster("Something went wrong", "warning");
      }
    } catch (e) {
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };
  useEffect(() => {
    getHospitalNames();
  }, []);

  const handleChange = (file) => {
    setUploadProgress(true);
    file.preventDefault();
    const fileUploaded = file.target.files[0];
    if (!fileUploaded) {
      setUploadProgress(false);
      return showToaster("Please select a file to continue.", "warning");
    }
    if (fileUploaded) {
      if (fileUploaded.type !== "text/csv") {
        setUploadProgress(false);
        return showToaster("Please upload a CSV file to continue.", "warning");
      }
    }
    const formData = new FormData();
    formData.append("csv", fileUploaded);
    formData.append("hospital_name", listUserCategory.label);
    try {
      uploadPDF(formData).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Documents Uploaded successfully", "success");
          console.log(response, "ressssssssssss");
          setUploadProgress(false);
          // FetchDetails()
          setQuestionDetails(response.data);
          history.push({
            pathname: "/details",
            state: {
              details: response.data.data,
              objectId: response.data.object_id,
            },
          });
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
    // handleFile(fileUploaded);
  };
  const handleUserTypeChange = (e, question_id) => {
    setListUserCategory(e);
  };
  useEffect(() => {
    generateUserTypeOptions();
  }, [userTypeCategory]);
  const generateUserTypeOptions = () => {
    if (userTypeCategory != undefined) {
      let userTypeOption = [];
      userTypeCategory.map((usertypes) => {
        userTypeOption.push({
          value: usertypes.hospital_code,
          label: usertypes.hospital_name,
          id: usertypes.type_id,
        });
        return true;
      });
      setUserTypes(userTypeOption);
    }
  };

  const getHospitalNames = () => {
    try {
      getHospitalName().then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Data fetched successfully", "success");
          console.log(response, "ressssssssssss");
          setUserTypeCategory(response.data.data);
          setListUserCategory({
            value: response.data.data[0].hospital_code,
            label: response.data.data[0].hospital_name,
            id: response.data.data[0].id,
          });
          setUploadProgress(false);
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      console.log(e, "aaaaaaaaaaaaa");
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };

  return (
    <>
      <Header />
      <div
        className="bg-image"
        style={{
          backgroundImage: 'url("../../assets/img/abcllogo.png")',
          height: "58vh",
        }}
      ></div>

      <div
        className="bg-image"
        style={{
          backgroundColor: "#f5eddf",
          height: "35vh",
        }}
      >
        {/* <div  style={{ marginTop: "10px"  }} className=" text-center mt-5"> */}
        <Box sx={{ margin: 1, borderRadius: "30px" }}>
          <div id="">
            <section id="content-wrapper1" className="">
              <div className="">
                <div>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                      padding: "20px",
                    }}
                  >
                    <div className="" style={{ backgroundColor: "white" }}>
                      <div className="text-center filename mb-3">
                        Start Mapping
                      </div>

                      <Select
                        value={listUserCategory}
                        onChange={(e) => {
                          handleUserTypeChange(e);
                        }}
                        options={userTypes}
                        className="hosdrop"
                        placeholder="Select Hospital"
                      />
                      <div class="file-upload-wrapper mb-3">
                        <input
                          type="text"
                          className="file-upload-input"
                          placeholder="Choose file to upload..."
                          readOnly
                          value={selectedFile ? selectedFile.name : ""}
                        />
                        <button
                          className="file-upload-button"
                          onClick={handleClick}
                        >
                          Browse
                        </button>
                        <input
                          type="file"
                          accept=".pdf"
                          id="myfile"
                          onChange={handleFileSelection}
                          ref={hiddenFileInput}
                          className="file-upload-hidden"
                        />
                      </div>
                      <div
                        style={{ marginTop: "4px" }}
                        className=" text-center "
                      >
                        <button
                          disabled={uploadProgress}
                          className="start-button"
                          onClick={handleFileUpload}
                        >
                          {uploadProgress && (
                            <i className="fas fa-circle-notch fa-spin me-1"></i>
                          )}
                          {uploadProgress ? "Uploading" : "Upload"}
                        </button>

                        <p class="note">
                          Note: Please upload the file in PDF format.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Box>
        {/* <button disabled={uploadProgress} className="button-upload btn btn-warning  me-2 " onClick={handleClick}>
                    {uploadProgress && <i className="fas fa-circle-notch fa-spin me-1"></i>} Upload File
                </button>
                <input
                    onClick={handleClick1}
                    accept='.csv'
                    id="myfile"
                    type="file"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }} // Make the file input element invisible
                /> */}

        {/* </div> */}
      </div>
      {/* <div className="text-center mt-3" style={{ fontFamily: "Inter" }}>
                Note: Please upload the file as CSV UTF-8 format.
            </div> */}
      <div class="footer">
        <img src="../../assets/img/techsvg.png" />
      </div>
    </>
  );
}
