import React, { useEffect, useState } from "react";
import Header from "./header";
import "./styles/preview.css";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
// import { saveAs } from 'file-saver';
import file from "../components/testdata/sample_data.xlsx";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/system";
// import { saveAs } from 'file-saver';
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Pagination from "@mui/material/Pagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showToaster } from "../services/helper";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {
  uploadStatusDetails,
  getFullDetails,
  generateServiceCode,
  addPrimaryCode,
  getFullDetailsByID,
} from "../services/api-servics";
import Select from "react-select";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
const apiUrl = process.env.REACT_APP_API_URL;
const steps = ["Data Extraction", "Verification", "Mapping"];
export default function MappedData(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(props, "propsprops");
  const [inputDataImgs, setInputDataImgs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [colHeaders, setColHeaders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const columnLabels = ["Flavour", "Food"];
  const rowLabels = ["Item 1", "Item 2"];
  const [isLoading, setIsLoading] = useState(false);
  const [addnew, openAddnew] = useState(false);
  const [modal, setModal] = useState(false);
  const [createmodal, setCreateModal] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [indiDetails, setIndiDetails] = useState({});
  const toggle = () => setModal(!modal);
  const [uploadProgress, setUploadProgress] = useState(false);
  const toggleCreate = () => setCreateModal(!createmodal);
  const [addedKey, setAdddedKey] = useState();
  const [generatedData, setGeneratedDetails] = useState(undefined);
  const [page, setPage] = useState(1);
  const [objetId, setObjectId] = useState("");
  const [progress, setProgress] = React.useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const itemsPerPage = 5;
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedItems = getDetails.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleSelectChange = (section, value) => {
    console.log(section, value, "valuesssssss");
    setSelectedValues((prevState) => ({
      ...prevState,
      [section]: value,
    }));
  };
  useEffect(() => {
    try {
      let data = {
        id: props?.location?.state?.objectId,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      fetch(`${apiUrl}/v1/base/view_entire_excel/`, requestOptions)
        .then((result) => result.blob())
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          const headers = jsonData[0];
          const rows = jsonData.slice(1);
          setColHeaders(headers);
          let array = [];
          headers.map((data) => {
            let details = {
              data: data.toLowerCase(),
              type: "text",
            };
            array.push(details);
          });
          setColumns(array);
          setData(rows);
          getFullDetail();
        })

        .catch((error) => {
          console.error("Error fetching or downloading the file:", error);
        });
    } catch (e) {
      console.error("Error fetching extracted data:", e);
    }
  }, []);

  const getFullDetail = () => {
    setIsLoading(true);
    try {
      let id = {
        id: props?.location?.state?.objectId,
      };

      getFullDetails(id).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          setIsLoading(false);
          showToaster("Data fetched successfully", "success");
          console.log(response, "ressssssssssss");
          setUploadProgress(false);
          setGetDetails(response.data.data);
          setObjectId(response.data.object_id);
        } else {
          setIsLoading(false);
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e, "aaaaaaaaaaaaa");
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };



  const getFullDetailByid = () => {
    setIsLoading(true);
    try {
      let id = {
        id: objetId,
      };

      getFullDetailsByID(id).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          setIsLoading(false);
          showToaster("Data fetched successfully", "success");
          console.log(response, "ressssssssssss");
          setUploadProgress(false);
          setGetDetails(response.data.data);
          setObjectId(response.data.object_id);
        } else {
          setIsLoading(false);
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e, "aaaaaaaaaaaaa");
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };
  const formik = useFormik({
    initialValues: {
      CategoryCode: "",
      CategoryCodeName: "",
      CHARGE_TYPE: "",
      Sub_ServiceDescription: "",
      SubServiceCode: "",
      Service_Description:""
    },
    validationSchema: Yup.object().shape({
      Sub_ServiceDescription: Yup.string().required(
        "Service Descriptionis required"
      ),
      Service_Description: Yup.string().required(
        "Service Description required"
      )
    
   
  }),
    onSubmit: (values) => {
      console.log("valueeee", values);
      try {
        let payload = {


          raw_name: generatedData?.name,
          id: objetId,
          Service_Description:values.Service_Description,
          Service_Code: values.Service_Code,
          Entity_Type: values.Entity_Type,
          Charge_Head: values.Charge_Head,
          Type_Of_Room: values.Type_Of_Room,
          Currency: values.Currency,
          Duration_Unit: values.Duration_Unit,
          Duration_From: values.Duration_From,
          Duration_To: values.Duration_To,
          Price_Per_Unit: values.Price_Per_Unit,
          Provider_Item_Code: values.Provider_Item_Code,
          Provider_Item_Name: values.Provider_Item_Name,
          Provider_Room_Type: values.Provider_Room_Type,
          Item_Type: values.Item_Type,
          Speciality: values.Speciality,
          Treatment_Type: values.Treatment_Type,
          Dependency_Parameter: values.Dependency_Parameter,
          Remark: values.Remark,
          ICD_Code:  values.ICD_Code,
          Sub_Service_Code: generatedData?.Sub_Service_Code,
          Price_Unit_Type: values.Price_Unit_Type,
          Price_Unit_Type_2: values.Price_Unit_Type2,
          Price_Unit_Type_3: values.Price_Unit_Type3,
          Sub_ServiceDescription:values.Sub_ServiceDescription



        };
        addPrimaryCode(payload).then(async (response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== null &&
            response.data !== undefined
          ) {
            showToaster("New details added successfully", "success");
            console.log(response, "ressssssssssss");
            setUploadProgress(false);
            setCreateModal(false);
            getFullDetailByid()
            // setGetDetails(response.data.data)
          } else {
            setUploadProgress(false);
            showToaster("Something went wrong", "warning");
          }
        });
      } catch (e) {
        console.log(e);
        formik.resetForm();
        showToaster("something went wronghtrft", "warning");
      }
    },
  });
  useEffect(() => {
    if (createmodal == true) {
      getServiceCode();
    }
  }, [createmodal]);

  const getServiceCode = () => {
    try {
      let payload = {
        raw_name: addedKey,
        id: props?.location?.state?.objectId,
      };

      generateServiceCode(payload).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Code generated successfully", "success");
          console.log(response, "ressssssssssss");
          setGeneratedDetails(response.data.data);
          setUploadProgress(false);
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      console.log(e, "aaaaaaaaaaaaa");
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };

  useEffect(() => {
    const defaultValues = {};
    getDetails.forEach((detail) => {
      Object.keys(detail).forEach((key) => {
        if (key !== "status") {
          const defaultOption = detail[key].find(
            (subItem) => subItem.status === true
          );
          defaultValues[key] = defaultOption
            ? {
              value: defaultOption["Sub_Service_Code"],
              label: defaultOption["Sub_ServiceDescription"],
              id: key,
              status: defaultOption["status"],
            }
            : null;
        }
      });
    });
    setSelectedValues(defaultValues);
  }, [getDetails]);
  const handleViewDetails = (key, detail) => {
    const selectedValue = selectedValues[key];
    if (selectedValue) {
      const defaultDetail = detail[key].find(
        (item) => item["Sub_Service_Code"] === selectedValue.value
      );
      setIndiDetails(defaultDetail);
    }
    setModal(true);
  };

  const handleAddDetails = (key, detail) => {
    setAdddedKey(key);
    console.log(key, detail, "dddddddddddddddddd");
    setCreateModal(true);
  };

  const updateChangeDetails = () => {
    try {
      let detailArray = [];
      Object.keys(selectedValues).map((key, subIndex) => {
        console.log(selectedValues, "keykeykeykeykey");
        if (selectedValues[key] !== null && selectedValues[key] !== undefined) {
          let details = {
            sub_service_code: selectedValues[key].id,
            status: true,
            instance_name: selectedValues[key].value,
          };
          detailArray.push(details);
        }
      });
      let apiPayload = {
        id: objetId,
        data: detailArray,
      };
      console.log(apiPayload, "apiPayloadapiPayload");

      uploadStatusDetails(apiPayload).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Data updated successfully", "success");

          history.push({
            pathname: "/finaldata",
            state: {
              objectId: objetId,
            },
          });

          setUploadProgress(false);
          // setGetDetails(response.data.data)
          // FetchDetails()
          // setQuestionDetails(response.data)
          // history.push({
          //     pathname: '/details',
          //     state: {
          //       details: response.data.data,
          //       objectId:response.data.object_id,

          //     }
          //   }
          //   );
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      console.log(e);
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % inputDataImgs.length);
  };
  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + inputDataImgs.length) % inputDataImgs.length
    );
  };

  //   useEffect(() => {
  //     props && setInputDataImgs(props.location.state.details.jpgs);
  //   }, [props]);

  const CustomStepper = styled(Stepper)(({ theme }) => ({
    backgroundColor: "transparent",
    "& .MuiStepLabel-root": {
      color: theme.palette.primary.main,
      "&.Mui-active": {
        color: theme.palette.secondary.main,
      },
      "&.Mui-completed": {
        color: theme.palette.success.main,
      },
    },
    "& .MuiStepIcon-root": {
      "&.Mui-active": {
        color: theme.palette.secondary.main,
      },
      "&.Mui-completed": {
        color: theme.palette.success.main,
      },
    },
  }));

  const handleAfterChange = (changes) => {
    if (changes) {
      changes.forEach(([row, prop, oldValue, newValue]) => {
        console.log(
          `Changed row ${row}, column ${prop} from ${oldValue} to ${newValue}`
        );
        // Update the data state if necessary
        const updatedData = [...data];
        updatedData[row][prop] = newValue;
        setData(updatedData);
      });
    }
  };
  return (
    <>
      <div className="container_preview">
        <div className="header_container">
          <div className="header_Left_container">Open Tariff Digitization</div>
          <div className="header_right_container">
            <div>
              <img src="../../assets/img/AvatarAbcl.png" />
            </div>
            <div>
              <img src="../../assets/img/chevron-down.png" />
            </div>
          </div>
        </div>
        <div className="container_inner">
          <div className="stepper">Data Mapping</div>
          {/* <Stepper
            steps={[
              { label: "Step 1" },
              { label: "Step 2" },
              { label: "Step 3" },
            ]}
            activeStep={2}
          /> */}
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={isLoading ? 2 : 3} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        color: "primary.main",
                        "&.Mui-active": {
                          color: "secondary.main",
                        },
                        "&.Mui-completed": {
                          color: "#0162DD",
                          fontWeight: "700",
                        },
                      },
                      "& .MuiStepIcon-root": {
                        "&.Mui-active": {
                          color: "secondary.main",
                        },
                        "&.Mui-completed": {
                          color: "success.main",
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div className="i_container">
            <div className="data_container">
              <div className="data_container_head">Extracted Data</div>
              <div className="data_img">
                <div>
                  <div style={{ marginTop: "20px" }}>
                    {data.length > 0 ? (
                      <HotTable
                        data={data}
                        colHeaders={colHeaders}
                        width="100%"
                        height="auto"
                        stretchH="all"
                        licenseKey="non-commercial-and-evaluation"
                        afterChange={handleAfterChange}
                        filters={true}
                        dropdownMenu={true}
                        columnSorting={true}
                        manualColumnResize={true}
                        contextMenu={true}
                        // Excel-like styling options
                        cell={(row, col) => {
                          return {
                            renderer: (
                              instance,
                              td,
                              row,
                              col,
                              prop,
                              value,
                              cellProperties
                            ) => {
                              // Change cell color based on conditions
                              if (value < 50) {
                                td.style.backgroundColor = "#ffcccb"; // Light red for values < 50
                              } else if (value >= 50 && value < 100) {
                                td.style.backgroundColor = "#ffffcc"; // Light yellow for values between 50 and 100
                              } else {
                                td.style.backgroundColor = "#ccffcc"; // Light green for values >= 100
                              }
                              // Call default text renderer
                              Handsontable.renderers.TextCell.renderer.apply(
                                this,
                                arguments
                              );
                            },
                          };
                        }}
                      />
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                  {/* <iframe src="https://devijeeja-my.sharepoint.com/:x:/g/personal/akhila_techvantagesystems_com/EXLAdoBHqedLtmMCtpNQU_IBfMHtvBa_UqwTPQVQzvuXow?rtime=kSl6aB2m3Eg" width="100%" height="650px"></iframe> */}
                  {/* {data.length > 0 ? (
                    <HotTable
                      data={data}
                      colHeaders={colHeaders}
                      width="100%"
                      height="auto"
                      stretchH="all"
                      licenseKey="non-commercial-and-evaluation"
                      afterChange={handleAfterChange}
                      filters={true}
                      dropdownMenu={true}
                      columnSorting={true}
                      manualColumnResize={true}
                      contextMenu={true}
                      // Excel-like styling options
                      cell={(row, col) => {
                        return {
                          renderer: (
                            instance,
                            td,
                            row,
                            col,
                            prop,
                            value,
                            cellProperties
                          ) => {
                            // Change cell color based on conditions
                            if (value < 50) {
                              td.style.backgroundColor = "#ffcccb"; // Light red for values < 50
                            } else if (value >= 50 && value < 100) {
                              td.style.backgroundColor = "#ffffcc"; // Light yellow for values between 50 and 100
                            } else {
                              td.style.backgroundColor = "#ccffcc"; // Light green for values >= 100
                            }
                            // Call default text renderer
                            Handsontable.renderers.TextCell.renderer.apply(
                              this,
                              arguments
                            );
                          },
                        };
                      }}
                    />
                  ) : (
                    <p>Loading...</p>
                  )} */}
                </div>
              </div>
            </div>
            <div className="data_container">
              <div className="data_container_head">Mapping Data</div>
              <div>
                <Table size="small" className="table" aria-label="purchases">
                  <thead>
                    <tr>
                      <th className="">
                        <span>Parameter</span>
                      </th>
                      <th className="">
                        <span>Mapped Parameter</span>
                      </th>

                      <th className="">
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>

                  <TableBody>
                    {paginatedItems.map((detail, index) => (
                      <React.Fragment key={index}>
                        {Object.keys(detail).map(
                          (key, subIndex) =>
                            key !== "status" && (
                              <tr
                                className={
                                  selectedValues[key]
                                    ? "validationcheckno"
                                    : "validationcheck"
                                }
                              >
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span className="truncate">{key}</span>
                                </td>
                                <td className="mb-3">
                                  {console.log(
                                    selectedValues[key],
                                    "selectedValuesselectedValues"
                                  )}
                                  <Select
                                    value={selectedValues[key] || []}
                                    onChange={(value) =>
                                      handleSelectChange(key, value)
                                    }
                                    options={detail[key].map((subItem) => ({
                                      value: subItem["Sub_Service_Code"],
                                      label: subItem["Sub_ServiceDescription"],
                                      id: key,
                                      status: subItem["status"],
                                    }))}
                                    // isMulti
                                    className="custom-input"
                                    placeholder="Select sub-services"
                                  />
                                </td>
                                <td>
                                  {selectedValues[key] ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleViewDetails(key, detail)
                                      }
                                      className="btn btn-info btn-sm mr-3"
                                    >
                                      View Details
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleAddDetails(key, detail)
                                      }
                                      style={{ backgroundColor: "#528493" }}
                                      className="btn btn-success btn-sm mr-3"
                                    >
                                      Add Details
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </div>

              {getDetails.length > 0 && (
                <div className="pagination-navsdaSS">
                  <Pagination
                    sx={{
                      "& .MuiPaginationItem-root": {
                        "&.Mui-selected": {
                          color: "#0162DD",
                          fontWeight: "700",
                          backgroundColor: "#D4F5FF",
                        },
                      },
                      "& .MuiPagination-ul": {
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "#D4F5FF",

                        // width: "fit-content",
                        // backgroundColor:" #8ca0ff",
                        padding: "5px",
                      },
                    }}
                    count={Math.ceil(getDetails.length / itemsPerPage)}
                    page={page}
                    onChange={handleChange}
                  />
                </div>
              )}
              {getDetails.length > 0 && (
                <button
                  // disabled={true}
                  type="submit"
                  style={{
                    backgroundColor: "#3f9a28",
                    marginTop: "",
                    height: "5px",
                  }}
                  onClick={() => {
                    updateChangeDetails();
                  }}
                  className="btn btn-success btn-sm"
                >
                  <img
                    src="../../assets/img/check.png"
                    style={{ width: "20px" }}
                    className="me-1"
                  />{" "}
                  Submit & Download
                </button>
              )}
              {/* {getDetails.length > 0 && (
                <span style={{ color: "red" }}>
                  Some issues found ! Please check other pages to rectify
                </span>
              )} */}
              {isLoading && (
                <>
                  <div style={{ marginTop: "161px" }}>
                    <CircularProgress />
                  </div>
                  <span style={{ marginTop: "121px" }}>
                    Please wait while we mapping your data
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal}
        fade={true}
        centered
        className="mmodal-dialog modal-dialog-centered modal-lg "
        toggle={toggle}
      >
        <ModalHeader
          style={{
            backgroundColor: "#9F1520",
            color: "#FFFF",
          }}
        >
          Parameter Details
        </ModalHeader>

        <div
          className="modal-content border-0 rounded-0  "
          style={{
            backgroundColor: "#f1dfe1",
          }}
        >
          <ModalBody>
            <div className="d-flex mb-5 parametrvalues">
              <div className="me-4 ">
                {indiDetails?.Provider_Room_Type}
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Price_Unit_Type}
                </span>
              </div>
              <div className="me-4 ">
                {indiDetails?.Provider_Room_Type_2}
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Price_Unit_Type_2}
                </span>
              </div>
              <div className="me-4 ">
                {indiDetails?.Provider_Room_Type_3}
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Price_Unit_Type_3}
                </span>
              </div>

              <div className="me-4 ">
                Charge Head
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Charge_Head}
                </span>
              </div>
              <div className="me-4 ">
                Speciality
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Speciality}
                </span>
              </div>
              <div className="me-4 ">
                Provider Item Code
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Provider_Item_Code}
                </span>
              </div>
              <div className="">
                Provider Item Name
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Provider_Item_Name}
                </span>
              </div>
            </div>

            <div className="d-flex mb-5 parametrvalues">
              <div className="me-4">
                Entity Type
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Entity_Type}
                </span>
              </div>
              <div className="me-4">
                Currency
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Currency}
                </span>
              </div>
              <div className="me-4">
                Item Type
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Item_Type}
                </span>
              </div>
              {/* <div className='me-4'>
                                    .
                                    <span className='d-flex subvalues mt-3'>wdwaewq</span>
                                </div> */}

              <div className="me-4">
                Service Description
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Service_Description}
                </span>
              </div>
              <div className="me-4">
                Service Code
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Service_Code}
                </span>
              </div>
              <div className="me-4">
                Provider Room Type
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Provider_Room_Type}
                </span>
              </div>
            </div>
            <div className="d-flex mb-5 parametrvalues">
              <div className="me-4">
                Type Of Room
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Type_Of_Room}
                </span>
              </div>

              <div className="me-4">
                General ward price
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Price_Unit_Type_3}
                </span>
              </div>
              <div className="me-4">
                Single room price
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Price_Unit_Type}
                </span>
              </div>
              <div className="me-4">
              Semi private price
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Price_Unit_Type_2}
                </span>
              </div>


              <div className="me-4">
                Start Date
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Start_Date}
                </span>
              </div>
              <div className="me-4">
                Treatment Type
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Treatment_Type}
                </span>
              </div>
            </div>
            <div className="d-flex mb-5 parametrvalues">

            
              <div className="me-4">
                Dependency Parameter
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Dependency_Parameter}
                </span>
              </div>

              <div className="me-4">
                Remark
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Remark}
                </span>
              </div>
              <div className="me-4">
                ICD Code
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.ICD_Code}
                </span>
              </div>
           
              <div className="me-4">
                Duration Unit
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Duration_Unit}
                </span>
              </div>

              <div className="me-4">
                Duration From
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Duration_From}
                </span>
              </div>
              <div className="me-4">
                Duration To
                <span className="d-flex subvalues mt-3">
                  {indiDetails?.Duration_To}
                </span>
              </div>
            </div>
          </ModalBody>
        </div>
        <ModalFooter
          style={{
            backgroundColor: "#f1dfe1",
          }}
        >
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={createmodal}
        fade={true}
        centered
        className="mmodal-dialog modal-dialog-centered modal-lg "
        toggle={toggleCreate}
      >
        <ModalHeader
          style={{
            backgroundColor: "#9f1520",
            color: "#FFFF",
          }}
        >
          Add New Parameter
        </ModalHeader>
        <div
          className="modal-content border-0 rounded-0  "
          style={{
            backgroundColor: "#f1dfe1",
          }}
        >
          <ModalBody>
            <div className="d-flex mb-5 parametrvalues">
              <form onSubmit={formik.handleSubmit}>
                <Form>
                  <Row className="mt-3">
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleEmail" style={{ fontSize: "14px" }}>
                          Parameter Name
                        </Label>
                        <Input
                          disabled={true}
                          value={generatedData?.name}
                          bsSize="sm"
                          id="exampleEmail"
                          name="Parameter Name"
                          placeholder="Enter Parameter Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                         Single Room
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Price Unit Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Price_Unit_Type}
                          type="text"
                          id="Price_Unit_Type"
                          name="Price_Unit_Type"
                        />
                        {formik.touched.Price_Unit_Type &&
                          formik.errors.Price_Unit_Type ? (
                          <div className="input-error-msg">
                            {formik.errors.Price_Unit_Type}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                        Sub Service Description
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Sub Service Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Sub_ServiceDescription}
                          type="text"
                          id="Sub_ServiceDescription"
                          name="Sub_ServiceDescription"
                        />
                        {formik.touched.Sub_ServiceDescription &&
                          formik.errors.Sub_ServiceDescription ? (
                          <div className="input-error-msg">
                            {formik.errors.Sub_ServiceDescription}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>


                  <Row className="mt-3">
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleEmail" style={{ fontSize: "14px" }}>
                         Semi private
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Price Unit Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Price_Unit_Type2}
                          type="text"
                          id="Price_Unit_Type2"
                          name="Price_Unit_Type2"
                        />
                        {formik.touched.Price_Unit_Type2 &&
                          formik.errors.Price_Unit_Type2 ? (
                          <div className="input-error-msg">
                            {formik.errors.Price_Unit_Type2}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                         General ward
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Price Unit Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Price_Unit_Type3}
                          type="text"
                          id="Price_Unit_Type3"
                          name="Price_Unit_Type3"
                        />
                        {formik.touched.Price_Unit_Type3 &&
                          formik.errors.Price_Unit_Type3 ? (
                          <div className="input-error-msg">
                            {formik.errors.Price_Unit_Type3}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>


                  <Row>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          Charge Head
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Charge Head"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Charge_Head}
                          type="text"
                          id="Charge_Head"
                          name="Charge_Head"
                        />
                        {formik.touched.Charge_Head &&
                          formik.errors.Charge_Head ? (
                          <div className="input-error-msg">
                            {formik.errors.Charge_Head}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          Speciality
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  Speciality"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Speciality}
                          id="Speciality"
                          name="Speciality"
                        />
                        {formik.touched.Speciality &&
                          formik.errors.Speciality ? (
                          <div className="input-error-msg">
                            {formik.errors.Speciality}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {/* <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Provider Item Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  Provider Item Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                          value={generatedData?.Provider_Item_Code}
                          type="text"
                          id="Provider_Item_Code"
                          name="Provider_Item_Code"
                        />
                        {formik.touched.Provider_Item_Code &&
                          formik.errors.Provider_Item_Code ? (
                          <div className="input-error-msg">
                            {formik.errors.Provider_Item_Code}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col> */}
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Provider Item Name
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter    Provider Item Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Provider_Item_Name}
                          type="text"
                          id="Provider_Item_Name"
                          name="Provider_Item_Name"
                        />
                        {formik.touched.Provider_Item_Name &&
                          formik.errors.Provider_Item_Name ? (
                          <div className="input-error-msg">
                            {formik.errors.Provider_Item_Name}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          Entity Type
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  Entity Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Entity_Type}
                          type="text"
                          id="Entity_Type"
                          name="Entity_Type"
                        />
                        {formik.touched.Entity_Type &&
                          formik.errors.Entity_Type ? (
                          <div className="input-error-msg">
                            {formik.errors.Entity_Type}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {/* <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          L3 code
                        </Label>
                        <Input
                          disabled={true}
                          value={generatedData?.L3_code}
                          bsSize="sm"
                          id="exampleZip"
                          name="L3 code"
                          placeholder="Enter L3 code"
                          type="text"
                        />
                      </FormGroup>
                    </Col> */}
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Currency
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Currency"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Currency}
                          type="text"
                          id=" Currency"
                          name=" Currency"
                        />
                        {formik.touched.Currency && formik.errors.Currency ? (
                          <div className="input-error-msg">
                            {formik.errors.Currency}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Sub Service Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Sub Service Code"
                          disabled={true}
                          value={generatedData?.Sub_Service_Code}
                          type="text"
                          id="SubServiceCode"
                          name="SubServiceCode"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Item Type
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  Item Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Item_Type}
                          type="text"
                          id=" Item_Type"
                          name=" Item_Type"
                        />
                        {formik.touched.Item_Type && formik.errors.Item_Type ? (
                          <div className="input-error-msg">
                            {formik.errors.Item_Type}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Service Description
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Service Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Service_Description}
                          type="text"
                          id="Service_Description"
                          name="Service_Description"
                        />
                        {formik.touched.Service_Description &&
                          formik.errors.Service_Description ? (
                          <div className="input-error-msg">
                            {formik.errors.Service_Description}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Service Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter   Service Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Service_Code}
                          type="text"
                          id=" Service_Code"
                          name=" Service_Code"
                        />
                        {formik.touched.Service_Code &&
                          formik.errors.Service_Code ? (
                          <div className="input-error-msg">
                            {formik.errors.Service_Code}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Type Of Room
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Type Of Room"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Type_Of_Room}
                          type="text"
                          id=" Type_Of_Room"
                          name=" Type_Of_Room"
                        />
                        {formik.touched.Type_Of_Room &&
                          formik.errors.Type_Of_Room ? (
                          <div className="input-error-msg">
                            {formik.errors.Type_Of_Room}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Duration Unit
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  Duration Unit"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Duration_Unit}
                          type="text"
                          id=" Duration_Unit"
                          name=" Duration_Unit"
                        />
                        {formik.touched.Duration_Unit &&
                          formik.errors.Duration_Unit ? (
                          <div className="input-error-msg">
                            {formik.errors.Duration_Unit}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Duration From
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Duration From"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Duration_From}
                          type="text"
                          id="Duration_From"
                          name="Duration_From"
                        />
                        {formik.touched.Duration_From &&
                          formik.errors.Duration_From ? (
                          <div className="input-error-msg">
                            {formik.errors.Duration_From}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Duration To
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Duration To"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Duration_To}
                          type="text"
                          id="Duration_To"
                          name="Duration_To"
                        />
                        {formik.touched.Duration_To &&
                          formik.errors.Duration_To ? (
                          <div className="input-error-msg">
                            {formik.errors.Duration_To}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Start Date
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Start Date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Start_Date}
                          type="text"
                          id="Start_Date"
                          name="Start_Date"
                        />
                        {formik.touched.Start_Date &&
                          formik.errors.Start_Date ? (
                          <div className="input-error-msg">
                            {formik.errors.Start_Date}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Provider Room Type
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  Provider Room Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Provider_Room_Type}
                          type="text"
                          id="Provider_Room_Type"
                          name="Provider_Room_Type"
                        />
                        {formik.touched.Provider_Room_Type &&
                          formik.errors.Provider_Room_Type ? (
                          <div className="input-error-msg">
                            {formik.errors.Provider_Room_Type}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Treatment Type
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Treatment Type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Treatment_Type}
                          type="text"
                          id="Treatment_Type"
                          name="Treatment_Type"
                        />
                        {formik.touched.Treatment_Type &&
                          formik.errors.Treatment_Type ? (
                          <div className="input-error-msg">
                            {formik.errors.Treatment_Type}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Dependency Parameter
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Dependency Parameter"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Dependency_Parameter}
                          type="text"
                          id="Dependency_Parameter"
                          name="Dependency_Parameter"
                        />
                        {formik.touched.Dependency_Parameter &&
                          formik.errors.Dependency_Parameter ? (
                          <div className="input-error-msg">
                            {formik.errors.Dependency_Parameter}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Remark
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Remark"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Remark}
                          type="text"
                          id="Remark"
                          name="Remark"
                        />
                        {formik.touched.Remark && formik.errors.Remark ? (
                          <div className="input-error-msg">
                            {formik.errors.Remark}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          ICD Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter  ICD Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ICD_Code}
                          type="text"
                          id="ICD_Code"
                          name="ICD_Code"
                        />
                        {formik.touched.ICD_Code && formik.errors.ICD_Code ? (
                          <div className="input-error-msg">
                            {formik.errors.ICD_Code}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <ModalFooter
                  style={{
                    backgroundColor: "#f1dfe1",
                  }}
                >
                  <Button color="secondary" onClick={toggleCreate}>
                    Close
                  </Button>
                  <button
                    type="submit"
                    style={{ backgroundColor: "#3f9a28", height: "10px" }}
                    className="btn btn-success  btn-sm"
                  >
                    <img
                      src="../../assets/img/check.png"
                      style={{ width: "20px" }}
                      className="me-1"
                    />{" "}
                    Submit
                  </button>
                </ModalFooter>
              </form>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
