import React, { useEffect, useState } from "react";
import Header from "./header";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Pagination from "@mui/material/Pagination";
import { showToaster } from "../services/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  uploadStatusDetails,
  getFullDetails,
  generateServiceCode,
  addPrimaryCode,
} from "../services/api-servics";
import Select from "react-select";
import {
  TablePagination,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
export default function DetailPage(props) {
  console.log(props, "proppppppppppppp");
  const [addnew, openAddnew] = useState(false);
  const [modal, setModal] = useState(false);
  const [createmodal, setCreateModal] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [indiDetails, setIndiDetails] = useState({});
  const toggle = () => setModal(!modal);
  const [uploadProgress, setUploadProgress] = useState(false);
  const toggleCreate = () => setCreateModal(!createmodal);
  const [addedKey, setAdddedKey] = useState();
  const [generatedData, setGeneratedDetails] = useState(undefined);
  useEffect(() => {
    if (props?.location?.state?.details !== undefined) {
      // setGetDetails(props?.location?.state?.details)
    }
  }, [props]);
  const handleSelectChange = (section, value) => {
    console.log(section, value, "valuesssssss");
    setSelectedValues((prevState) => ({
      ...prevState,
      [section]: value,
    }));
  };
  useEffect(() => {
    getFullDetail();
  }, []);

  const getFullDetail = () => {
    try {
      let id = {
        id: props?.location?.state?.objectId,
      };

      getFullDetails(id).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Data fetched successfully", "success");
          console.log(response, "ressssssssssss");
          setUploadProgress(false);
          setGetDetails(response.data.data);
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      console.log(e, "aaaaaaaaaaaaa");
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };

  const formik = useFormik({
    initialValues: {
      CategoryCode: "",
      CategoryCodeName: "",
      ServiceDescription: "",
      SubServiceDescription: "",
      CHARGE_TYPE: "",
      Description: "",
      SubServiceCode: "",
    },
    validationSchema: Yup.object().shape({
      CategoryCode: Yup.string().required("Category Code is required"),
      CategoryCodeName: Yup.string().required("Category Code Name is required"),
      ServiceDescription: Yup.string().required(
        "Service Descriptionis required"
      ),
      SubServiceDescription: Yup.string().required(
        "Sub Service Description is required"
      ),
      CHARGE_TYPE: Yup.string().required("CHARGE TYPE is required"),
      SubServiceCode: Yup.string().required(" Service Code is required"),
      Description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      console.log("valueeee", values);
      try {
        let payload = {
          raw_name: generatedData?.name,
          id: props?.location?.state?.objectId,
          Sub_Service_Code: generatedData?.Sub_Service_Code,
          L3_code: generatedData?.L3_code,
          Category_Code: JSON.parse(values.CategoryCode),
          Category_Code_Name: values.CategoryCodeName,
          Service_Code: JSON.parse(values.SubServiceCode),
          Service_Description: values.ServiceDescription,
          Sub_Service_Description: values.SubServiceDescription,
          CHARGE_TYPE: values.CHARGE_TYPE,
          Discription: values.Description,
        };
        addPrimaryCode(payload).then(async (response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== null &&
            response.data !== undefined
          ) {
            showToaster("New details added successfully", "success");
            console.log(response, "ressssssssssss");
            setUploadProgress(false);
            setCreateModal(false);
            getFullDetail();
            // setGetDetails(response.data.data)
          } else {
            setUploadProgress(false);
            showToaster("Something went wrong", "warning");
          }
        });
      } catch (e) {
        console.log(e);
        formik.resetForm();
        showToaster("something went wronghtrft", "warning");
      }
    },
  });
  useEffect(() => {
    if (createmodal == true) {
      getServiceCode();
    }
  }, [createmodal]);

  const getServiceCode = () => {
    try {
      let payload = {
        raw_name: addedKey,
        id: props?.location?.state?.objectId,
      };

      generateServiceCode(payload).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Code generated successfully", "success");
          console.log(response, "ressssssssssss");
          setGeneratedDetails(response.data.data);
          setUploadProgress(false);
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      console.log(e, "aaaaaaaaaaaaa");
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };

  useEffect(() => {
    const defaultValues = {};
    getDetails.forEach((detail) => {
      Object.keys(detail).forEach((key) => {
        if (key !== "status") {
          const defaultOption = detail[key].find(
            (subItem) => subItem.status === true
          );
          defaultValues[key] = defaultOption
            ? {
                value: defaultOption["Sub_Service_Code"],
                label: defaultOption["Sub_Service_Description"],
                id: key,
                status: defaultOption["status"],
              }
            : null;
        }
      });
    });
    setSelectedValues(defaultValues);
  }, [getDetails]);
  const handleViewDetails = (key, detail) => {
    const selectedValue = selectedValues[key];
    if (selectedValue) {
      const defaultDetail = detail[key].find(
        (item) => item["Sub_Service_Code"] === selectedValue.value
      );
      setIndiDetails(defaultDetail);
    }
    setModal(true);
  };

  const handleAddDetails = (key, detail) => {
    setAdddedKey(key);
    console.log(key, detail, "dddddddddddddddddd");
    setCreateModal(true);
  };

  const updateChangeDetails = () => {
    try {
      let detailArray = [];
      Object.keys(selectedValues).map((key, subIndex) => {
        console.log(selectedValues[key], "keykeykeykeykey");
        if (selectedValues[key] !== null && selectedValues[key] !== undefined) {
          let details = {
            sub_service_code: selectedValues[key].id,
            status: true,
            instance_name: selectedValues[key].value,
          };
          detailArray.push(details);
        }
      });
      let apiPayload = {
        id: props?.location?.state?.objectId,
        data: detailArray,
      };
      console.log(apiPayload, "apiPayloadapiPayload");

      uploadStatusDetails(apiPayload).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          showToaster("Data updated successfully", "success");
          console.log(response, "ressssssssssss");
          setUploadProgress(false);
          // setGetDetails(response.data.data)
          // FetchDetails()
          // setQuestionDetails(response.data)
          // history.push({
          //     pathname: '/details',
          //     state: {
          //       details: response.data.data,
          //       objectId:response.data.object_id,

          //     }
          //   }
          //   );
        } else {
          setUploadProgress(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      console.log(e);
      setUploadProgress(false);
      showToaster("Something went wrong", "warning");
    }
  };

  return (
    <div>
      <Header />
      <div
        className="bg-image"
        style={{
          backgroundColor: "#f5eddf",
          height: "92vh",
        }}
      ></div>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "white",
            borderRadius: "25px",
          }}
          colSpan={6}
        >
          <Box sx={{ margin: 1, borderRadius: "30px" }}>
            <div id="">
              <section id="content-wrapper" className="">
                <div className="">
                  <div>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "30px",
                        padding: "20px",
                      }}
                    >
                      <div className="" style={{ backgroundColor: "white" }}>
                        <div className="text-center filename">
                          Search Details
                        </div>
                        <Table
                          size="small"
                          className="table"
                          aria-label="purchases"
                        >
                          <thead>
                            <tr>
                              <th className="">
                                <span>Parameter</span>
                              </th>
                              <th className="">
                                <span>Mapped Parameter</span>
                              </th>

                              <th className="">
                                <span>Action</span>
                              </th>
                            </tr>
                          </thead>

                          <TableBody>
                            {getDetails.map((detail, index) => (
                              <React.Fragment key={index}>
                                {Object.keys(detail).map(
                                  (key, subIndex) =>
                                    key !== "status" && (
                                      <tr
                                        className={
                                          selectedValues[key]
                                            ? ""
                                            : "validationcheck"
                                        }
                                      >
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <span className="truncate">
                                            {key}
                                          </span>
                                        </td>
                                        <td className="mb-3">
                                          {console.log(
                                            selectedValues[key],
                                            "selectedValuesselectedValues"
                                          )}
                                          <Select
                                            value={selectedValues[key] || []}
                                            onChange={(value) =>
                                              handleSelectChange(key, value)
                                            }
                                            options={detail[key].map(
                                              (subItem) => ({
                                                value:
                                                  subItem["Sub_Service_Code"],
                                                label:
                                                  subItem[
                                                    "Sub_Service_Description"
                                                  ],
                                                id: key,
                                                status: subItem["status"],
                                              })
                                            )}
                                            // isMulti
                                            className="custom-input"
                                            placeholder="Select sub-services"
                                          />
                                        </td>
                                        <td>
                                          {selectedValues[key] ? (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleViewDetails(key, detail)
                                              }
                                              className="btn btn-info btn-sm mr-3"
                                            >
                                              View Details
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleAddDetails(key, detail)
                                              }
                                              className="btn btn-success btn-sm mr-3"
                                            >
                                              Add Details
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                )}
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                        <div
                          className="text-center"
                          style={{
                            marginTop: "30px",
                            alignItems: "baseline",
                            marginLeft: "192px",
                          }}
                        >
                          <Stack>
                            {/* <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        component="div"
                                                        count={10}
                                                        rowsPerPage={5}
                                                        page={2}
                                                        // onPageChange={handleChangePage}
                                                        // onRowsPerPageChange={handleChangeRowsPerPage}
                                                        showLastButton={true}
                                                        showFirstButton={true}
                                                    /> */}
                            <Pagination
                              count={10}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                  }}
                                  {...item}
                                />
                              )}
                            />
                          </Stack>
                        </div>
                        <div
                          className="text-center"
                          style={{ marginTop: "30px" }}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              updateChangeDetails();
                            }}
                            style={{ backgroundColor: "#3f9a28" }}
                            className="btn btn-success"
                          >
                            <img
                              src="../../assets/img/check.png"
                              style={{ width: "20px" }}
                              className="me-3"
                            />
                            Save & Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Box>
        </TableCell>
      </TableRow>
      <div class="footer">
        <img src="../../assets/img/techsvg.png" />
      </div>

      <Modal
        isOpen={modal}
        fade={true}
        centered
        className="mmodal-dialog modal-dialog-centered modal-lg "
        toggle={toggle}
      >
        <ModalHeader
          style={{
            backgroundColor: "#9F1520",
            color: "#FFFF",
          }}
        >
          Parameter Details
        </ModalHeader>

        <div
          className="modal-content border-0 rounded-0  "
          style={{
            backgroundColor: "#f1dfe1",
          }}
        >
          <ModalBody>
            <div className="d-flex mb-5 parametrvalues">
              <div className="me-4 ">
                Category Code
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Category_Code}
                </span>
              </div>
              <div className="me-4 ">
                Category Code Name
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Category_Code_Name}
                </span>
              </div>
              <div className="me-4 ">
                Service Code
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Service_Code}
                </span>
              </div>
              <div className="me-4 ">
                Service Description
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Service_Description}
                </span>
              </div>
              <div className="">
                Sub Service Code
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Sub_Service_Code}
                </span>
              </div>
            </div>

            <div className="d-flex mb-5 parametrvalues">
              <div className="me-4">
                Sub Service Description
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Sub_Service_Description}
                </span>
              </div>
              <div className="me-4">
                CHARGE_TYPE
                <span className="d-flex subvalues mt-3">
                  {indiDetails.CHARGE_TYPE}
                </span>
              </div>
              <div className="me-4">
                L3 code
                <span className="d-flex subvalues mt-3">
                  {indiDetails.L3_code}
                </span>
              </div>
              {/* <div className='me-4'>
                                    .
                                    <span className='d-flex subvalues mt-3'>wdwaewq</span>
                                </div> */}
              <div>
                Discription
                <span className="d-flex subvalues mt-3">
                  {indiDetails.Discription}
                </span>
              </div>
            </div>
          </ModalBody>
        </div>
        <ModalFooter
          style={{
            backgroundColor: "#f1dfe1",
          }}
        >
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={createmodal}
        fade={true}
        centered
        className="mmodal-dialog modal-dialog-centered modal-lg "
        toggle={toggleCreate}
      >
        <ModalHeader
          style={{
            backgroundColor: "#9f1520",
            color: "#FFFF",
          }}
        >
          Add New Parameter
        </ModalHeader>
        <div
          className="modal-content border-0 rounded-0  "
          style={{
            backgroundColor: "#f1dfe1",
          }}
        >
          <ModalBody>
            <div className="d-flex mb-5 parametrvalues">
              <form onSubmit={formik.handleSubmit}>
                <Form>
                  <Row className="mt-3">
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleEmail" style={{ fontSize: "14px" }}>
                          Parameter Name
                        </Label>
                        <Input
                          disabled={true}
                          value={generatedData?.name}
                          bsSize="sm"
                          id="exampleEmail"
                          name="Parameter Name"
                          placeholder="Enter Parameter Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          Category Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Category Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.CategoryCode}
                          type="text"
                          id="CategoryCode"
                          name="CategoryCode"
                        />
                        {formik.touched.CategoryCode &&
                        formik.errors.CategoryCode ? (
                          <div className="input-error-msg">
                            {formik.errors.CategoryCode}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          Category Code Name
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Category Code Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.CategoryCodeName}
                          type="text"
                          id="CategoryCodeName"
                          name="CategoryCodeName"
                        />
                        {formik.touched.CategoryCodeName &&
                        formik.errors.CategoryCodeName ? (
                          <div className="input-error-msg">
                            {formik.errors.CategoryCodeName}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          Service Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Service Code"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.SubServiceCode}
                          id="SubServiceCode"
                          name="SubServiceCode"
                        />
                        {formik.touched.SubServiceCode &&
                        formik.errors.SubServiceCode ? (
                          <div className="input-error-msg">
                            {formik.errors.SubServiceCode}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Service Description
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Service Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ServiceDescription}
                          type="text"
                          id="ServiceDescription"
                          name="ServiceDescription"
                        />
                        {formik.touched.ServiceDescription &&
                        formik.errors.ServiceDescription ? (
                          <div className="input-error-msg">
                            {formik.errors.ServiceDescription}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Sub Service Description
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Sub Service Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.SubServiceDescription}
                          type="text"
                          id="SubServiceDescription"
                          name="SubServiceDescription"
                        />
                        {formik.touched.SubServiceDescription &&
                        formik.errors.SubServiceDescription ? (
                          <div className="input-error-msg">
                            {formik.errors.SubServiceDescription}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          CHARGE_TYPE
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter CHARGE_TYPE"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.CHARGE_TYPE}
                          type="text"
                          id="CHARGE_TYPE"
                          name="CHARGE_TYPE"
                        />
                        {formik.touched.CHARGE_TYPE &&
                        formik.errors.CHARGE_TYPE ? (
                          <div className="input-error-msg">
                            {formik.errors.CHARGE_TYPE}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label
                          for="examplePassword"
                          style={{ fontSize: "14px" }}
                        >
                          L3 code
                        </Label>
                        <Input
                          disabled={true}
                          value={generatedData?.L3_code}
                          bsSize="sm"
                          id="exampleZip"
                          name="L3 code"
                          placeholder="Enter L3 code"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Description
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Description}
                          type="text"
                          id="Description"
                          name="Description"
                        />
                        {formik.touched.Description &&
                        formik.errors.Description ? (
                          <div className="input-error-msg">
                            {formik.errors.Description}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mt-3">
                      <FormGroup>
                        <Label for="exampleZip" style={{ fontSize: "14px" }}>
                          Sub Service Code
                        </Label>
                        <Input
                          bsSize="sm"
                          placeholder="Enter Sub Service Code"
                          disabled={true}
                          value={generatedData?.Sub_Service_Code}
                          type="text"
                          id="SubServiceCode"
                          name="SubServiceCode"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <ModalFooter
                  style={{
                    backgroundColor: "#f1dfe1",
                  }}
                >
                  <Button color="secondary" onClick={toggleCreate}>
                    Close
                  </Button>
                  <button
                    type="submit"
                    style={{ backgroundColor: "#3f9a28", height: "10px" }}
                    className="btn btn-success  btn-sm"
                  >
                    <img
                      src="../../assets/img/check.png"
                      style={{ width: "20px" }}
                      className="me-1"
                    />{" "}
                    Submit
                  </button>
                </ModalFooter>
              </form>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
