import {
  AxioslibUser,
} from "../lib/axiolib-api";
import * as XLSX from "xlsx";
import queryString from "query-string";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
export const checkUserLogin = (body) => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/LoginDetails`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};




export const login = (body) => {
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/userlogin`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};


export const uploadPDF = (body) => {
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/upload_file_to_process/`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};


export const uploadStatusDetails = (body) => {
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/update_details_by_id/`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getFullDetails = (body) => {
  console.log(body,"bodybody");
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/process_file/`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};

export const getFullDetailsByID = (body) => {
  console.log(body,"bodybody");
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/view_by_id/`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};


export const getHospitalName = (body) => {
  console.log(body,"bodybody");
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/hospital_view/`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};




export const generateServiceCode = (body) => {
  console.log(body,"bodybody");
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/generate_new_code/`,body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};



export const addPrimaryCode = (body) => {
  console.log(body,"errorrrr?");
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/save_new_code_to_existing_detail/`,body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        console.log(e,"catch?");
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const fetchExtractedCsv = (body) => {
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/v1/base/return_csv_result/`, body, {
      headers: {
        'Content-Type': 'application/json', // Assuming you're sending JSON data
      },
      responseType: 'blob',
    })
      .then((response) => {
        if (response.status === 200) {
          
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};


